.el-carousel {
  height: 100%;
}
.el-carousel__container {
  height: 100% !important;
}
.el-carousel__button {
  border-radius: 50% !important;
  width: 0.08rem !important;
  height: 0.08rem !important;
}
.el-carousel__indicators--horizontal {
  bottom: 0 !important;
  left: 0.16rem !important;
  -webkit-transform: unset !important;
  transform: unset !important;
}
/deep/ .swiper {
  height: 100%;
}
/deep/ .swiper .swiper-item {
  background-size: 100% 100%;
}