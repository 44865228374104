.pdfbook .container[data-v-5fb3e545] {
  padding: 1.2rem 0;
}
.pdfbook .container .swiperbox[data-v-5fb3e545] {
  height: 8rem;
}
.pdfbook .container .swiperbox .swiper[data-v-5fb3e545] {
  height: 100%;
}
.pdfbook .container .swiperbox .swiper-item[data-v-5fb3e545] {
  width: 100%;
  display: block;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.pdfbook .container .swiperbox .swiper-item .title[data-v-5fb3e545] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000000;
  opacity: 0.6;
  color: #fff;
  font-size: var(--f14);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding: 0.14rem 0.18rem 0.36rem;
  white-space: nowrap;
}